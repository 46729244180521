<template>
  <a class="fb-button" :href="authLink">
    <img class="button-logo" src="@/assets/f-logo58-white.png" alt="fb" />
    <span class="button-text">{{ $t(text) }}</span>
  </a>
</template>
<script>
import config from "@/config";
import { getCookie } from "@/cookie";

export default {
  props: {
    text: {
      type: String,
      default: "continue_with_facebook",
    },
    redirectUrl: {
      type: String,
      required: true,
    },
    useToken: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    authLink() {
      const url = new URL(`${config.apiGate}/auth/athlete_users/facebook`);

      if (this.useToken) {
        const authorization = getCookie("user");
        url.searchParams.append("token", authorization.substr(7));
      }

      const redirectUrl = new URL("/auth/conn", window.location.origin);
      redirectUrl.searchParams.append("source", this.redirectUrl);
      redirectUrl.searchParams.append("auth_type", "Facebook");
      url.searchParams.append("redirect_url", redirectUrl.toString());

      return url.toString();
    },
  },
};
</script>
<style lang="scss" scoped>
.fb-button {
  position: relative;
  height: 50px;
  border-radius: 30px;
  background-color: #407ada;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // padding: 0 14px;
  text-decoration: none;

  .button-logo {
    height: 20px;
    width: 20px;
    position: absolute;
    left: 14px;
  }
  .button-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-transform: uppercase;
  }
  &:hover {
    background-color: #3250b9;
  }
}
</style>